
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserModel } from '@/models/user';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';

@Component({})
export default class Login extends Vue {
  @Prop({default: null})
  public app!: any;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public loginMessage: string = '';
  public user: UserModel = new UserModel();
  public shopifyStore: string = '';
  public emailRules: any = [
    this.isEmailExist,
    this.isEmailValidate,
  ];
  public passwordRules: any = [
    this.isPasswordExist,
  ];
  public loginViaShopify: any = true;
  public created() {
    if (this.loginViaShopify) {
      this.user.email = '';
      this.user.password = '';
      this.loginMessage = 'Please enter the Shopify Store URL';
    } else {
      this.user.email = localStorage.getItem('current_loggedin_email') || '';
      this.loginMessage = 'Please enter the login account';
    }
    
    if (localStorage.getItem('shopify_store')) {
      this.user.storeName = localStorage.getItem('shopify_store') || '';
    }
    const shopifyStoreParam = this.$route.query.store + '';
    if (shopifyStoreParam !== 'undefined' && this.user.storeName !== shopifyStoreParam) {
      this.user.storeName = shopifyStoreParam;
    }
  }

  public reloadApp() {
    Redirect.create(this.app).dispatch(Redirect.Action.APP, '/reports/4/inventory-tracking');
  }
  public get isEmbeddedMode() {
    return isShopifyEmbedded();
  }

  public isStoreNameExist(v: any) {
    return !!v || this.$t('user_management.Shopify Store Name is required');
  }

  public isEmailExist(v: any) {
    return !!v || this.$t('user_management.E-mail is required');
  }

  public isPasswordExist(v: any) {
    return !!v || this.$t('user_management.Password is required');
  }

  public isPasswordMin8(v: any) {
    if (v.length < 8) {
      return this.$t('user_management.Password must be at least 8 characters');
    }
    return true;
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || this.$t('user_management.E-mail must be valid');
  }

  public checkEmailValidate(v: any) {
    return /.+@.+/.test(v);
  }

  public validateUserData() {
    return !(!this.user.email || !this.user.password || !this.user.storeName
             || !this.isEmailValidate(this.user.email));
  }

  public get getLoginMessage() {
    if (this.loginViaShopify) {
      return 'Please enter the Shopify Store URL';
    } else {
      return 'Please enter the login account';
    }
  }

  public async login() {
    if (this.loginViaShopify) {
      this.loginMessage = 'Please enter the Shopify Store URL';
    } else {
      this.loginMessage = 'Please enter the login account';
    }
    if (this.validateUserData()) {
      this.loading = true;
      try {
        await this.user.login();
        this.$emit('logged-in');
        localStorage.setItem('current_loggedin_email', this.user.email);
      } catch (e: any) {
        if ([403, 404].includes(e.response.status)) {
          this.loginMessage = 'The login account is invalid';
          this.error = true;
        }
      }
      this.loading = false;
    } else {
      this.error = true;
      this.loginMessage = 'The login account is invalid';
    }
  }

  public loginViaShopifyAdmin() {
    this.loginViaShopify = true;
    this.loginMessage = 'Please enter the Shopify Store URL';
    // generate the shopify store url
    let shopifyStore = this.user.storeName;
    //check if the store name is valid
    if (!shopifyStore) {
      this.error = true;
      this.loginMessage = 'Please enter the Shopify Store URL';
      return;
    }
    //check if the store name contain .myshopify.com then remove it from the store name
    if (shopifyStore.includes('.myshopify.com')) {
      shopifyStore = shopifyStore.replace('.myshopify.com', '');
    }
    localStorage.setItem('shopify_store', shopifyStore);
    const redirectUrl = `https://admin.shopify.com/store/${shopifyStore}/apps/assisty${this.$route.fullPath}`;
    window.location.href = redirectUrl;

  }
}
